import { render, staticRenderFns } from "./CheckSpecialGoods.vue?vue&type=template&id=034bfe84&scoped=true&"
import script from "./CheckSpecialGoods.vue?vue&type=script&lang=js&"
export * from "./CheckSpecialGoods.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "034bfe84",
  null
  
)

export default component.exports